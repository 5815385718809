import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from '@contentful/rich-text-types'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Iframe from 'react-iframe';

const NewsletterPage = ({
  data: {
    contentfulPageContent,
  },
}) => {

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        let url = node.data.target.fields.file['en-US'].url;
        return <img className="post-image" src={'https:' + url} alt=""/>
      }
    },
  }

  return (
    <Layout>
      <div className="hero-header">
        <div className="headline">{contentfulPageContent.title}</div>
        <div className="primary-content">
          {documentToReactComponents(contentfulPageContent.bodyContent.json, options)}
        </div>
        <Iframe src="https://mattford.substack.com/embed"
          width="100%"
          frameBorder="0"
        />
      </div>
    </Layout>
  )
}

export default NewsletterPage
export const pageQuery = graphql`
  query newsletterPageQuery {
    contentfulPageContent(slug: {eq: "newsletter"}) {
      title
      bodyContent {
        json
      }
    }
  }
`